<template>
  <div class="volunteer_detail_page home_page">
    <div class="list_title" v-if="userInfo">
      <p class="score_wrap">
        <span v-if="!userInfo.is_new">{{ userInfo.type }}</span>
        <span v-if="!userInfo.is_new">
          {{ tag }}
        </span>
        <span v-if="userInfo.is_new"
          ><b>志愿表{{ tag }}</b></span
        >
        <span>{{ point }}分</span>
        <span v-if="showDetailInfo">
          <i v-if="showDetailInfo.position > 0"
            >{{ showDetailInfo.position }}名</i
          >
        </span>
      </p>
      <div v-if="showDetailInfo && userInfo.is_new">
        共{{ showDetailInfo.raw_data.length }}个
      </div>
    </div>
    <div class="content">
      <!-- 老高考志愿展示 -->
      <div v-if="showDetailInfo">
        <div class="list_nr" v-if="userInfo && !userInfo.is_new">
          <ul class="school_list old_school_list">
            <li
              v-for="(item, index) in showDetailInfo.raw_data"
              :key="index"
              v-if="item.zhiyuan"
            >
              <div class="item" :class="{ expand_item: item.expand }">
                <div class="percent">{{ item.percent }}%</div>
                <div class="info">
                  <div class="school_info">
                    <span class="school_name" @click="viewSchoolDetail(item)">{{
                      item.name
                    }}</span>
                    <span class="tag_wrap">
                      <span
                        v-for="(tag, tindex) in item.tag_list"
                        :key="tindex"
                      >
                        {{ tag }}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span class="category">{{ item.category }}</span>
                    <span class="zone_name">{{ item.zone_name }}</span>
                    <span class="zone_name">{{ item.code }}</span>
                  </div>
                  <div>
                    <div>
                      <span>2019招生计划</span
                      ><span>{{ item.people_count }}</span>
                    </div>
                    <div>
                      <span>{{ item.data[0].year }}最低分</span
                      ><span>{{ item.data[0].min_points }}</span>
                      <span class="people_count">录取人数 -</span>
                    </div>
                  </div>
                </div>
                <div class="major_btn">
                  <div class="select_zhiyuan" v-if="item.zhiyuan">
                    <span>
                      {{ item.zhiyuan }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="major_list_wrap">
                <ul class="major_list">
                  <li
                    v-for="(majorItem, mindex) in item.majorData"
                    :key="mindex"
                  >
                    <div class="item_wrap" v-if="majorItem.isSelect">
                      <div class="item">
                        <div class="percent">
                          <div>{{ majorItem.percent }}%</div>
                          <div class="text_intro">专业概率</div>
                        </div>
                        <div class="info">
                          <div class="school_info">
                            <span
                              class="school_name"
                              @click="viewMajorDetail(item, majorItem)"
                            >
                              {{ majorItem.name }}
                            </span>
                          </div>
                          <div>
                            <span class="category"
                              >学制：{{ majorItem.years }}</span
                            >
                            <span class="zone_name"
                              >学费：{{ majorItem.money }}</span
                            >
                          </div>
                          <div>
                            <div>
                              <span>2019招生计划</span
                              ><span>{{ item.people_count }}</span>
                            </div>
                            <div>
                              <span>{{ majorItem.data[0].year }}最低分</span
                              ><span>{{ majorItem.data[0].min_points }}</span>
                              <span>录取人数 8</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <!-- 新高考 -->

        <ul v-else class="school_list new_school_list fill_list">
          <li
            v-for="(item, index) in showDetailInfo.raw_data"
            :key="index"
            class="item"
          >
            <div class="index_num">
              <span class="key">{{ index + 1 }}</span>
            </div>
            <div class="major_info">
              <div class="major_name">
                {{ item.majorInfo.name }}
              </div>
              <div class="gray_label">
                <span>{{ showDetailInfo.subject_str }}</span>
                <span>{{ currentYear }}计划</span>
                <span>学制{{ item.majorInfo.years }}</span>
                <span>学费{{ item.majorInfo.money }}</span>
              </div>
              <div class="school_name new_school_name">
                <span>{{ item.schoolInfo.name }}</span>
                <span class="gray_label code_label" v-if="item.schoolInfo.code"
                  >代码{{ item.schoolInfo.code }}</span
                >
              </div>
            </div>

            <div
              class="major_order_wrap gray_label code_label"
              v-if="item.majorInfo.code"
            >
              代码{{ item.majorInfo.code }}
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="loading_data">正在加载数据</div>
    </div>

    <!-- 学校招生详情 -->
    <van-popup
      class="school_modal"
      v-model="schoolDetailPopupShow"
      closeable
      position="bottom"
      :style="{ height: '75%' }"
    >
      <h2>
        {{ schoolDetail.name }}
      </h2>
      <div class="title">2019招生计划</div>
      <ul class="zhaosheng_jihua">
        <li>
          <span>概率</span>
          <!-- <span>代码</span> -->
          <span>人数</span>
        </li>
        <li>
          <span>{{ schoolDetail.percent }}</span>
          <!-- <span>{{ schoolDetail.code }}</span> -->
          <span>{{ schoolDetail.people_count }}</span>
        </li>
      </ul>
      <div class="title">院校历年招生统计</div>
      <el-table
        class="history_detail"
        :data="schoolDetail.data"
        style="width: 100%"
      >
        <el-table-column prop="year" label="年份" width="60px">
        </el-table-column>
        <el-table-column label="录取数" width="60px">
          <template slot-scope="scope">
            {{ scope.row.people_count || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低分">
          <template slot-scope="scope">
            {{ scope.row.min_points || "-" }}
          </template>
        </el-table-column>

        <el-table-column label="线差">
          <template slot-scope="scope">
            {{ scope.row.gap_points || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低位次">
          <template slot-scope="scope">
            {{ scope.row.min_position || "-" }}
          </template>
        </el-table-column>
      </el-table>
    </van-popup>

    <!-- 专业招生详情 -->
    <van-popup
      class="major_modal"
      v-model="majorDetailPopupShow"
      closeable
      position="bottom"
      :style="{ height: '75%' }"
    >
      <h2>
        {{ majorDetail.name }}
      </h2>
      <div class="school_name">
        {{ schoolDetail.name }}
      </div>
      <div class="title">2019招生计划</div>
      <ul class="zhaosheng_jihua">
        <li>
          <span>录取概率</span>
          <span>代码</span>
          <span>人数</span>
          <span>学费</span>
          <span>学制</span>
        </li>
        <li>
          <span>{{ majorDetail.percent }}%</span>
          <span>{{ majorDetail.code }}</span>
          <span>{{ majorDetail.people_count }}</span>
          <span>{{ majorDetail.money }}</span>
          <span>{{ majorDetail.years }}</span>
        </li>
      </ul>
      <div class="title">专业历年招生统计</div>
      <el-table
        class="history_detail"
        :data="majorDetail.data"
        style="width: 100%"
      >
        <el-table-column prop="year" label="年份" width="60px">
        </el-table-column>
        <el-table-column label="录取数" width="60px">
          <template slot-scope="scope">
            {{ scope.row.people_count || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低分">
          <template slot-scope="scope">
            {{ scope.row.min_points || "-" }}
          </template>
        </el-table-column>

        <el-table-column label="线差">
          <template slot-scope="scope">
            {{ scope.row.gap_points || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低位次">
          <template slot-scope="scope">
            {{ scope.row.min_position || "-" }}
          </template>
        </el-table-column>
      </el-table>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      id: "",
      tag: "",
      point: "",
      showDetailInfo: null,
      schoolDetail: {},
      schoolDetailPopupShow: false,
      majorDetailPopupShow: false,
      majorDetail: {}
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      isNew: state => state.isNew
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  created() {
    this.id = this.$route.params.id;
    const arr = this.$route.params.type.split("-");
    this.tag = arr[0];
    this.point = arr[1];
    this.getVolunteerDetail();
  },
  methods: {
    async getVolunteerDetail() {
      const apiUrl = "/api/volunteer/" + this.id + "/";
      try {
        const response = await this.$axios.get(apiUrl);
        if (response.data.errno === 0) {
          this.showDetailInfo = response.data.results;
          console.log("detail---", this.showDetailInfo);
          // 按照志愿顺序排序
          if (this.userInfo && !this.userInfo.is_new) {
            this.showDetailInfo.raw_data.sort((a, b) => {
              const textA = a.zhiyuan.toUpperCase();
              const textB = b.zhiyuan.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    tableRowClassName: function(row, index) {
      if (!row.row.isSelect) {
        return "hidden_row";
      }
      return "";
    },
    handleDelete(row) {},
    handleEdit(row) {},
    viewSchoolDetail(item) {
      this.schoolDetail = item;
      this.schoolDetailPopupShow = true;
    },
    viewMajorDetail(item, majorItem) {
      this.schoolDetail = item;
      this.majorDetail = majorItem;
      this.majorDetailPopupShow = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.volunteer_detail_page {
  .list_title {
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    height: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.4rem;
    z-index: 10;
    .score_wrap {
      font-size: 0.26rem;
      color: #333;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #fff;
      span {
        margin-right: 0.3rem;
      }
    }
  }
  .content {
    min-height: 100vh;
    padding-bottom: 0.3rem;
    .loading_data {
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      color: #999;
    }
  }

  ul.school_ul {
    margin-top: 30px;
    li {
      margin-bottom: 30px;
    }
    .zhiyuan_wrap {
      .zhiyuan_label {
        width: 130px;
        background: #910000;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        color: #fff;
        text-align: center;
        font-size: 16px;
      }
    }
    .zhiyuan_detail {
      border: 1px solid #ccc;
      line-height: 28px;
      display: flex;
      min-height: 300px;
      .zhiyuan_left {
        padding: 20px;
        flex-basis: 35%;
        border-right: 1px solid #ccc;
        .school_title {
          height: 56px;
          border-bottom: 1px solid #e5e5e5;
        }
        .sub_label {
          margin-top: 10px;
          color: #999;
        }
        .school_id {
          display: inline-block;
          background: #e6e6e6;
          padding: 0 7px;
          border-radius: 7px;
          line-height: 22px;
          color: #910000;
          margin-right: 8px;
        }
      }
      .zhiyuan_right {
        padding: 20px;
        flex-basis: 75%;
        .hidden_row {
          display: none;
        }
      }
    }
  }

  .list_nr {
    margin-bottom: 1.5rem;
  }

  ul.old_school_list {
    > li {
      margin-bottom: 0.2rem;
      border-bottom: 1px solid #ccc;
      > .item {
        border-bottom: 1px solid #ccc;
      }
    }
  }
  ul.new_school_list {
    li {
      margin-bottom: 0.2rem;
    }
  }
  ul.fill_list {
    li {
      border-bottom: 1px solid #ececec;
      padding: 0.3rem;
      display: flex;
      justify-content: space-between;
      background: #fff;
      margin-bottom: 0.12rem;
      &:last-child {
        border: none;
      }
      .key {
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        background: #910000;
        color: #fff;
      }
      .index_num {
        flex-basis: 8%;
      }
      .major_info {
        flex-basis: 80%;
        .new_school_name {
          font-weight: normal;
        }
        .code_label {
          margin-left: 0.14rem !important;
        }
        .major_name {
          font-size: 0.28rem;
          font-weight: 600;
          overflow: hidden;
          color: #333;
          margin-bottom: 0.1rem;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .gray_label {
          color: #999;
          font-size: 0.2rem;
          margin: 0.12rem 0 0.2rem 0;
          span {
            margin: 0 0.1rem;
          }
        }
        .school_name {
          color: #525252;
          padding-top: 0.2rem;
          margin-top: 0.2rem;
          font-size: 0.24rem;
          border-top: 1px dashed #dadada;
        }
      }
    }
  }
  ul.school_list {
    li {
      text-align: left;
      background: #fff;
      color: #999;
      overflow: hidden;
      box-sizing: border-box;
      .major_order_wrap {
        color: #888;
        flex-basis: 12%;
      }
      .item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 10px;
        .percent {
          flex-basis: 17%;
          text-align: center;
          color: #910000;
          font-size: 0.3rem;
          font-weight: 600;
        }
        .info {
          flex-basis: 80%;
          padding-left: 5px;
        }
      }

      .school_name {
        color: #333;
        font-weight: 600;
        font-size: 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .zone_name {
        margin-left: 10px;
      }
      .people_count {
        margin-left: 20px;
      }
      .major_num {
        display: inline-block;
        border-radius: 15px;
        border: 1px solid #ddd;
        width: 66px;
        text-align: center;
        background: #fff;
      }
      .select_zhiyuan {
        width: 66px;
        text-align: center;
        color: #910000;
        font-size: 0.26rem;
        font-weight: 600;
        margin-top: 2px;
        span {
          display: inline-block;
          width: 0.44rem;
          height: 0.44rem;
          background: #910000;
          border-radius: 50%;
          color: #fff;
          text-align: center;
          line-height: 0.44rem;
        }
      }
      .selected_major {
        background: #910000;
        color: #fff;
      }
    }
  }
  .major_list_wrap {
    max-height: 300px;
    overflow-y: scroll;
    .sub_loading {
      text-align: center;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #999;
    }
    .percent {
      color: #444 !important;
    }
  }
  ul.major_list {
    font-size: 0.22rem;
    .item_wrap {
      border-radius: 0px;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border: none;
      }

      .school_name {
        color: #e04a00;
        font-size: 0.26rem;
      }
      .text_intro {
        font-size: 0.18rem;
        color: #999;
        text-align: center;
      }
    }
  }
  .school_modal,
  .major_modal {
    text-align: left;
    padding: 15px;
    box-sizing: border-box;
    h2 {
      font-size: 18px;
      margin-bottom: 0;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .school_name {
      font-size: 12px;
      color: #999;
    }
    .zhaosheng_jihua {
      margin-top: 5px;
      li {
        display: flex;
        height: 34px;
        line-height: 34px;
        justify-content: space-around;
      }
      li:nth-child(1) {
        background: #ececec;
      }
      li:nth-child(2) {
        border-bottom: 1px solid #eeeeee;
      }
    }
    .title {
      margin: 20px 0 10px 0;
    }
    .history_detail {
      font-size: 13px;
    }
  }

  .el-table {
    .tag_wrap {
      margin-top: 5px;
      span {
        display: inline-block;
        padding: 0 4px;
        border: 1px solid #910000;
        border-radius: 3px;
        line-height: 18px;
        font-size: 13px;
        color: #910000;
        margin-right: 5px;
      }
    }
  }
}
</style>
